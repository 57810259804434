<template>
  <div :class="{'campo-grv': type === 'campo', 'item': type === 'acessorio'}">
    <label v-if="type === 'campo'">{{ campo.nome }}</label>
    <template v-if="type === 'acessorio' && campo.valores && campo.valores.type && campo.valores.type === 'true/false'">
      <select v-model="model" :disabled="disabled">
        <option :value="null">-</option>
        <option :value="1">Sim</option>
        <option :value="0">Não</option>
      </select>
    </template>
    <erp-input v-else-if="isInput" type="text" v-model="model" :disabled="disabled" />
    <erp-input v-else-if="isTextarea" type="text" v-model="model" :disabled="disabled" />
    <select v-else-if="isSelect" v-model="model" :disabled="disabled">
      <option :value="null">Escolha</option>
      <option v-for="(val, key) in campo.valores" :key="key" :value="val.value || val">{{ val.value || val }}</option>
    </select>
    <template v-else-if="isOption">
      <select v-model="model" :disabled="disabled">
        <option :value="null">-</option>
        <option v-for="(val, key) in campo.valores" :key="'campoValor-' + key" :value="val">{{ val }}</option>
      </select>
    </template>
  </div>
</template>

<script>
import {ErpInput} from "uloc-vue-plugin-erp"

export default {
  name: 'CampoGrv',
  inject: ['remocaoContainer'],
  components: {
    ErpInput
  },
  props: {
    value: null,
    campo: {
      type: Object,
      required: true
    },
    type: {
      type: String,
      default: 'campo'
    }
  },
  data () {
    return {
      model: this.value,
      disabled: this.campo.disabled || false
    }
  },
  mounted () {
    this.$nextTick(() => {
      this.bindModel()
    })
  },
  watch: {
    model (v) {
      this.$emit('input', v)
    }
  },
  computed: {
    tipoCampo () {
      return Number(this.campo.tipo)
    },
    isInput () {
      return this.tipoCampo === 1
    },
    isTextarea () {
      return this.tipoCampo === 2
    },
    isSelect () {
      return this.tipoCampo === 3
    },
    isOption () {
      return this.tipoCampo === 4
    },
    isCheckbox () {
      return this.tipoCampo === 5
    }
  },
  methods: {
    bindModel () {
      if (this.value || !this.campo.bemCampoNome || !this.campo.bemCampoNome.length) {
        this.model = this.value
        return
      }
      let value
      const campoBem = this.campo.bemCampoNome.split('.')
      let pointer = this.remocaoContainer.remocao.bem
      for (let i = 0; i < campoBem.length; i++) {
        if (typeof pointer[campoBem[i]] !== 'undefined') {
          value = pointer = pointer[campoBem[i]]
        }
      }
      this.disabled = true
      this.campo.disabled = true
      this.model = value
    }
  }
}
</script>