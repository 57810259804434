<template>
  <div class="wrapper padder-lg">
    <div class="row">
      <div class="col">
        <div v-if="solicitacao.origem" class="row">
          <local-detalhes label="Origem" v-model="solicitacao.origem" nomap/>
        </div>
        <div v-if="solicitacao.destino" class="row m-t">
          <local-detalhes label="Destino" v-model="solicitacao.destino" nomap/>
        </div>
        <div v-if="entrega" class="">
          <div class="row m-t">
            <erp-s-field
                class="font-bold"
                view="lr"
                label="Vistoriador:"
                label-width="60px"
            >
              <span v-if="!vistoria"
                    class="font-normal font-11"><u>{{ solicitacao.autorizacaoEntrega.usuario.pessoa.nome }}</u></span>
              <erp-input v-else v-model="fake.vistoriador" disable/>
            </erp-s-field>
          </div>
          <div class="row m-t-xs">
            <!--<span v-if="!vistoria" class="font-normal font-11 text-positive">Entregue no destino final</span>-->
            <status-select label-width="60px" :disable="!vistoria" label="Status:" placeholder="Selecione"
                           field-view="lr"
                           v-model="solicitacao.status"/>
          </div>
          <div class="row m-t-xs">
            <erp-s-field
                class="font-bold"
                view="lr"
                label="Recebido em:"
                label-width="60px"
            >
              <span
                  class="font-normal font-11">{{ solicitacao.autorizacaoEntrega.data.date|formatDate('dd/MM/yyyy HH:mm:ss') }}</span>
              <!-- TODO: Atualizar autorização de entrega corretamente após vistoria e aprovação do recebimento -->
            </erp-s-field>
          </div>
        </div>
      </div>
      <div class="col">
        <reboquista-info v-if="solicitacao.reboquista" :solicitacao="solicitacao"/>
      </div>
    </div>

    <div class="row checklist-labels m-t-lg">
      <div v-if="checklistReboquista" class="col padder"
           :style="!vistoria ? 'width: 50%; max-width: 50%' : 'width: 100%'">
        <erp-label tag="div" class="bg-white" :label="remocao && remocao.reboquista ? 'Checklist realizado pelo Reboquista' : 'Checklist realizado pelo vistoriador sem OS de Remoção'">
          <div class="row">
            <div class="col">
              <div class="text-center m-b-md">
                <e-btn  @click="downloadGrv(solicitacao, 1)" icon="file-pdf" icon-type="fa" label="Baixar Laudo / GRV do Reboquista" />
              </div>
              <table class="table-checklist">
                <tr v-for="campo in checklistReboquista.campos" :key="campo.id">
                  <td class="text-right text-nowrap font-bold table-checklist-label">{{ campo.campo.nome }}</td>
                  <td class="table-checklist-content" v-if="!vistoria">{{ campo.valor }}</td>
                  <td class="table-checklist-content" v-else>
                    <erp-input v-model="campo.valor"/>
                  </td>
                </tr>
              </table>
            </div>
            <div class="col flex items-center" v-if="vistoria">
              <div class="vistoriaDivergencias"
                   :class="!checklistReboquista.divergencias ? 'semDivergencia' : null">
                <span
                    v-if="checklistReboquista.divergencias">Vistoria com <strong>{{ checklistReboquista.divergencias }}</strong> divergência{{ Number(checklistReboquista.divergencias).length > 1 ? 's' : '' }}</span>
                <span v-else>
                  <div class="text-center m-b-xs text-2x"><u-icon name="thumbs-up" type="fa"/></div>
                  Vistoria sem divergências
                </span>
              </div>
            </div>
          </div>
          <div class="row">
            <table class="table-checklist acessorios m-t">
              <tr v-for="equip in checklistReboquista.acessorios" :key="equip.id">
                <td style="width: 40%" class="text-left font-bold">{{ equip.acessorio.nome }}</td>
                <td class="text-right">

                  <div class="text-positive checklist-campo-valores">
                    <checklist-acessorio-valor
                        :class="{'divergente': !vistoria && equip.valorConfirmado !== null && String(equip.valor) !== String(equip.valorConfirmado)}"
                        :acessorio="equip"/>
                    <checklist-acessorio-valor
                        v-if="!vistoria && equip.valorConfirmado !== null && equip.valor !== equip.valorConfirmado"
                        :acessorio="equip" vistoria/>
                  </div>
                </td>
                <td class="checklist-campos-vistoria" style="width: 70px; padding-left: 10px" v-if="vistoria">
                  <campo-grv
                      :class="{'naoPreenchido': equip.valorConfirmado === null, 'divergente': equip.valorConfirmado !== null &&  String(equip.valor) !== String(equip.valorConfirmado)}"
                      :campo="equip.acessorio" v-model="equip.valorConfirmado" type="acessorio"/>
                </td>
                <td v-if="vistoria && equip.valorConfirmado !== null && String(equip.valor) !== String(equip.valorConfirmado)">
                  <div class="m-l flex column justify-between">
                    <div>
                      <erp-input placeholder="Obs. divergência" class="col-grow" v-model="equip.divergencia"/>
                    </div>
                  </div>
                </td>
                <td v-else>
                  <div class="m-l" v-if="equip.valorConfirmado !== null && String(equip.valor) === String(equip.valorConfirmado)"><i class="fa fa-check text-positive"></i></div>
                  &nbsp;
                </td>
              </tr>
            </table>
          </div>
        </erp-label>
      </div>

      <div v-if="!vistoria" class="col padder" style="width: 50%; max-width: 50%">
        <erp-label tag="div" class="bg-white" label="Vistoria, Divergências e Anexos">
          <div class="text-center m-b-md">
            <e-btn @click="downloadGrv(solicitacao, 2)" icon="file-pdf" icon-type="fa" label="Baixar Laudo / GRV do Vistoriador" />
          </div>
          <status-checklist :data="historicoVistoria" />
          <!--          <div @click="windowFotos(solicitacao.id, solicitacao)"
                         class="m-t cursor-pointer flex items-center text-grey-9"
                         v-if="solicitacao.checklist.vistoriador && typeof solicitacao.checklist.vistoriador.fotos !== 'undefined' && solicitacao.checklist.vistoriador.fotos.length > 0">
                      <u-icon name="camera-retro" type="fa" icon-style="solid" class="m-r-xs text-grey-9" />
                      {{solicitacao.checklist.vistoriador.fotos.length}} fotos do vistoriador
                    </div>-->
          <!--<div v-if="solicitacao.reboquista" class="m-t">
            <e-btn v-if="!vistoria" @click="vistoria = true" label="Vistoriar veículo"/> <1!-- @TODO -1->
          </div> -->
          <div class="m-b m-t">Anexos</div>
          <div class="lista-fotos-remocao-checklist flex">
            <div v-for="arquivo in arquivos" :key="arquivo.id" class="arquivo">
              <a @click="download(arquivo)" target="_blank">
                <img v-if="arquivo.versions && arquivo.versions.thumb" :src="arquivo.versions.thumb.url" />
                <div class="arquivo-text" v-else-if="arquivo.tipo === 100"><i class="fa fa-file-signature"></i> Assinatura do reboquista</div>
                <div class="arquivo-text" v-else-if="arquivo.tipo === 101"><i class="fa fa-file-signature"></i> Assinatura do vistoriador</div>
                <div class="arquivo-text" v-else-if="arquivo.tipo === 102"><i class="fa fa-file-signature"></i> Assinatura do responsável</div>
                <div v-else></div>
                <div class="divergencia text-negative" v-if="arquivo.divergente">
                  <strong>Divergência:</strong> {{arquivo.observacao }}
                </div>
                <div v-else class="divergencia text-negative">
                  <strong style="opacity: 0;">D</strong> &nbsp;
                </div>
                <div class="font-thin small m-t-xs">Registro em {{arquivo.createdAt.date|formatDate}}</div>
              </a>
              <!-- <div v-if="vistoriador && foto.obsVistoriador">{{foto.obsVistoriador}}</div> @TODO -->
            </div>
          </div>
        </erp-label>
      </div>
    </div>

    <div class="row checklist-labels m-t-lg">
      <div class="col padder" style="width: 50%; max-width: 50%">
        <div class="m-b-xs">Observações / Avarias / Reboquista:</div>
        <textarea rows="5" v-model="checklistReboquista.observacaoReboquista" class="w-full wrapper-sm font-11"/>
      </div>
      <div class="col padder" style="width: 50%; max-width: 50%">
        <div class="m-b-xs">Observações / Avarias / Vistoriador:</div>
        <textarea rows="5" v-model="checklistReboquista.observacaoVistoriador" class="w-full wrapper-sm font-11"/>
      </div>
    </div>

    <div class="inline-block window-actions m-t text-right w-full">
      <u-btn label="Fechar" icon="chevron-left" icon-type="fa" icon-style="light" flat no-caps
             @click="$uloc.window.close($root.wid)"/>
      <!--      <u-btn label="Salvar (F2)" icon="save" icon-type="fa" icon-style="light" flat no-caps
                   @click="save"
                   v-shortkey="['f2']" @shortkey.native="save"/>-->
    </div>
  </div>
</template>

<script>
import LocalDetalhes from './SolicitacaoComponent/LocalDetalhes'
import ReboquistaInfo from './SolicitacaoComponent/ReboquistaInfo'
import {ErpSField, ErpLabel, ErpInput, ErpSelect} from 'uloc-vue-plugin-erp'
import StatusChecklist from './SolicitacaoComponent/StatusChecklist'
import windowFotos from '../../windows/fotos'
import StatusSelect from '../include/StatusSelect'
import ChecklistAcessorioValor from "@/components/remocao/components/include/ChecklistAcessorioValor"
import CampoGrv from "@/components/remocao/components/include/CampoGrv"
import {downloadArquivoChecklist, downloadGrv, salvarGrv} from "@/domain/remocao/services"
import {donwloadFile} from "@/utils/downloadFile";

export default {
  name: 'ChecklistRemocao',
  components: {
    CampoGrv,
    ChecklistAcessorioValor,
    StatusSelect,
    StatusChecklist,
    ReboquistaInfo,
    LocalDetalhes,
    ErpSField,
    ErpLabel,
    ErpInput,
    // ErpSelect
  },
  props: {
    solicitacao: {required: true},
    remocao: {required: true},
    checklist: {required: true}
  },
  provide: function () {
    return {
      remocaoContainer: this
    }
  },
  data() {
    return {
      vistoria: false,
      fake: {
        vistoriador: '-'
      }
    }
  },
  computed: {
    entrega() {
      return null
    },
    checklistReboquista() {
      return this.checklist
      let checklist
      if (!this.solicitacao.reboquista) {
        return this.checklistVistoriador
      }
      this.solicitacao.historico.map(h => {
        if (h.checklist && !h.vistoriador) {
          checklist = h.checklist
        }
      })
      console.log('CHECK', checklist)
      return checklist
    },
    checklistVistoriador() {
      return this.checklist
      let checklist
      this.solicitacao.historico.map(h => {
        if (h.checklist && h.vistoriador) {
          checklist = h.checklist
        }
      })
      return checklist
    },
    historicoVistoria () {
      let h = this.solicitacao.historico.filter(h => h.checklist && h.vistoriador)
      if (h && Array.isArray(h) && h.length) {
        return h[0]
      }
      return null
    },
    arquivos () {
      let checklist
      this.solicitacao.historico.map(h => {
        if (h.checklist) {
          checklist = h.checklist
        }
      })
      if (!checklist) {
        return []
      }
      return checklist.arquivos
    },
  },
  mounted() {
    console.log(this.solicitacao)
    console.log(this.remocao)
    console.log('CHECKLIST', this.checklist)
  },
  methods: {
    windowFotos: windowFotos,
    download (arquivo) {
      downloadArquivoChecklist(arquivo.id)
          .then(response => {
            arquivo.downloading = true
            const link = document.createElement('a')
            link.href = response.data.url
            link.target = '_blank'
            document.body.appendChild(link)
            link.click();
            window.setTimeout(() => {
              document.body.removeChild(link)
              arquivo.downloading = false
            }, 100)
            // window.open(response.data.url)
          })
          .catch(error => {
            arquivo.downloading = false
            this.alertApiError(error)
          })
    },
    downloadGrv (s, emissor) {
      downloadGrv(s.id, emissor)
          .then(response => {
            donwloadFile(response)
          })
          .catch(error => {
            this.alertApiError(error)
          })
    },
    save() {
      console.log('save')
    },
    salvar () {
      this.$uloc.dialog({
        title: 'Atenção',
        message: 'Confirmar alteração da vistoria?',
        cancel: true,
        color: 'primary'
      }).onOk(data => {
        this.$uloc.loading.show()
        salvarGrv(this.s.id, this.s)
            .then(response => {
              this.$uloc.loading.hide()
            })
            .catch(response => {
              console.log(response)
              this.$uloc.loading.hide()
              this.alertApiError(response)
            })
      }).onCancel(() => {
        console.log('Cancelado')
      })
    }
  }
}
</script>

<style lang="stylus">
.checklist-labels {
  display flex
  justify-content space-between

  .erp-label {
    flex 1
    height 100%
  }
}

.table-checklist {
  width 100%
  border-collapse collapse
  max-width 100%

  td {
    padding 3px 0

    &.table-checklist-content {
      padding-left 10px
    }
  }

  &.acessorios {
    td {
      border-bottom #999999 1px dashed
      padding-bottom 5px
    }
  }
}

.vistoriaDivergencias {
  width 256px
  margin auto
  border #FF1919 1px dashed
  padding 20px
  text-align center
  color #DE5656
  text-transform uppercase

  &.semDivergencia {
    border-color #3fdba0
    color #3fdba0
  }
}

.erp-select-input {
  &.bg-negative {
    background-color #FBCFCF !important
  }
}

.checklist-campo-valores {
  display flex
  text-align right
  justify-content flex-end

  .checklist-campo-valor {
    margin-left 4px

    &.divergente {
      text-decoration line-through !important
      opacity 0.5
    }
  }
}

.checklist-campos-vistoria {
  min-width 180px
  > div > select, > div > input {
    width 100%
  }
  .naoPreenchido{
    select{
      background-color #cabaff
    }
  }
  .divergente{
    background-color #FBCFCF
    select{
      background-color #FBCFCF
    }
  }
}

.lista-fotos-remocao-checklist{
  .arquivo{
    padding 5px
    display flex
    width 31%
    border #ccc 1px dotted
    margin 5px
    a, .arquivo-text{
      color #222222
      display flex
      flex-direction column
      flex 1
      justify-content center
      align-items center
    }
  }
}
</style>
