import { render, staticRenderFns } from "./StatusRemocaoSelector.vue?vue&type=template&id=4e76137a&scoped=true"
import script from "./StatusRemocaoSelector.vue?vue&type=script&lang=js"
export * from "./StatusRemocaoSelector.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4e76137a",
  null
  
)

export default component.exports