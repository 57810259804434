<template>
  <div class="checklist-campo-valor" :class="bindCampoClass(acessorio)">
    <strong v-if="valor === true || valor === false || valor === 0 || valor === 1 || valor === '0' || valor === '1'" :class="{yes: valor === true}">{{ isTrue(valor) ? 'Sim' : 'Não' }}</strong>
    <strong v-else-if="valor && valor.length">{{ valor }}</strong>
    <strong v-else class="text-purple">
      <i class="naoInformado">-</i>
    </strong>
  </div>
</template>

<script>
export default {
  name: "ChecklistAcessorioValor",
  props: {
    acessorio: {required: true},
    vistoria: {type: Boolean, default: false}
  },
  computed: {
    valor () {
      return this.vistoria ? this.acessorio.valorConfirmado : this.acessorio.valor
    }
  },
  methods: {
    bindCampoClass (campo) {
      const css = []
      if (!this.vistoria) {
        let emptyAndRequired = false
        if (this.isEmpty(campo.valor) && campo.obrigatorio) {
          emptyAndRequired = true
        }
        if (emptyAndRequired) {
          css.push('requiredError')
          // this.errors.push(campo.id)
        }
      }
      let v = this.valor
      if (v === 0 || v === '0' || v === false){
        css.push('text-negative')
      }
      if (v === 1 || v === '1' || v === true){
        css.push('text-positive')
      }
      return css
    },
    isEmpty (v) {
      return (v !== true && v !== false) && (!v || !String(v).length || v === '' || v === ' ')
    },
    isTrue (v) {
      return v !== 0 && v !== '0' && v !== false
    }
  }
}
</script>
